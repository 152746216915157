import http from '../helpers/http-common';

class CabinFeedService {
  getCabinFeed(id) {
    return http.get(`/CabinFeed/GetCabinFeedAsync/${id}`)
  }

  deleteCabinFeed(id) {
    return http.delete('/CabinFeed/DeleteCabinFeedAsync', {
      data: {
        cabinId: id
      }
    })
  }

  getCabinPost(id) {
    return http.get(`/CabinFeed/GetCabinPostAsync/${id}`);
  }

  addCabinPost(cabinId, userName, content) {
    return http.post('/CabinFeed/AddCabinPostAsync', {
      cabinId,
      userName,
      content
    })
  }

  deleteCabinPost(id) {
    return http.delete('/CabinFeed/DeleteCabinPostAsync/', {
      data: {
        cabinPostId: id
      }
    })
  }

  addCabinPostContent(
    cabinPostId,
    contentLanguageId,
    contentTypeId,
    text,
    contentAsBase64,
    contentName,
    defaultStatus,
    sequence
  ) {
    return http.post('/CabinFeed/AddCabinPostContentAsync', {
      cabinPostId,
      contentLanguageId,
      contentTypeId,
      text,
      contentAsBase64,
      contentName,
      default: defaultStatus,
      sequence
    })
  }

  updateCabinPostContent(
    id,
    contentLanguageId,
    contentTypeId,
    text,
    contentAsBase64,
    contentName,
    defaultStatus,
    sequence
  ) {
    return http.post('/CabinFeed/UpdateCabinPostContentAsync', {
      id,
      contentLanguageId,
      contentTypeId,
      text,
      contentAsBase64,
      contentName,
      default: defaultStatus,
      sequence
    })
  }

  deleteCabinPostContent(id) {
    return http.delete('/CabinFeed/DeleteCabinPostContentAsync', {
      data: {
        cabinPostContentId: id
      }
    })
  }

  addCabinPostComment(cabinPostId, userName, comment) {
    return http.post('/CabinFeed/AddCabinPostCommentAsync', {
      cabinPostId,
      userName,
      comment
    })
  }

  updateCabinPostComment(cabinPostCommentId, comment) {
    return http.put('/CabinFeed/UpdateCabinPostCommentAsync', {
      cabinPostCommentId,
      comment
    })
  }

  deleteCabinPostComment(id) {
    return http.delete('/CabinFeed/DeleteCabinPostCommentAsync', {
      data: {
        cabinPostCommentId: id
      }
    })
  }

  addCabinPostLike(cabinPostId, userName, postLikeStatus) {
    return http.post('/CabinFeed/AddCabinPostLikeAsync', {
      cabinPostId,
      userName,
      postLikeStatus
    })
  }

  deleteCabinPostLike(id) {
    return http.delete('/CabinFeed/DeleteCabinPostLikeAsync', {
      data: {
        cabinPostLikeId: id
      }
    })
  }

}

export default new CabinFeedService();
