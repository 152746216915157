<template>
    <transition name="slide">
        <div class="drawer-modal" v-if="isVisible">
            <div class="window">
                <slot></slot>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    name: 'DrawerModal',
    data: () => ({
        isVisible: false,
    }),
    methods: {
        open() {
            this.isVisible = true
        },
        close() {
            this.isVisible = false
        },
    },
}
</script>
<style lang="scss" scoped>
  .slide-enter, .slide-leave-active {
    bottom: -100%;
  }
  .drawer-modal {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .window {
    position: relative;
    background: #fff;
    height: 100%;
    width: 100%;
    max-width: 100%;
    height: 100%;
    @media (min-width: $breakpoint-mobile) {
      border-radius: 5px;
      box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
      width: 480px;
      height: 480px;
      margin-left: auto;
      margin-right: auto;
    }
  }
</style>
