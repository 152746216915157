<template>
  <section class="cabin-book">
    <div v-if="cabinFeedList.length" class="cabin-book_list" >
      <Button class="desktop-button" color="darkgreen" text="Nytt innlegg" symbol="plus" @click="toggleAddCabinFeed()"></Button>
      <Button class="mobile-button" color="green" symbol="plus" @click="toggleAddCabinFeed()"></Button>
      <CabinFeedItem
        v-for="(item, index) in cabinFeedList"
        :key="index" :cabinFeedItem="item"
        :storageToken="cabin.storageTokenQuery"
        :cabinUserRoleId="cabinUserRoleId"
        @addedComment="addComment"
        @toggleLike="likeToggled"
        @deleteCabinFeedPost="deletePost"
      >
      </CabinFeedItem>
    </div>
    <div v-else class="cabin-book_none">
      <div class="cabin-book_none--inner">
        <BookClosed />
        <h1>Hyttebok</h1>
        <p>Skriv ditt første innlegg for å komme i gang</p>
        <Button @click="toggleAddCabinFeed()" color="lightgreen" text="Opprett nytt innlegg" size="large" symbol="plus"></Button>
      </div>
    </div>
    <AddCabinPostDialog ref="addCabinPostDialog"></AddCabinPostDialog>
  </section>
</template>
<script>
import CabinFeedService from '@/services/CabinFeedService';
import CabinFeedItem from '@/components/cabinBook/CabinFeedItem';
import Button from '@/components/Button';
import AddCabinPostDialog from '@/components/modals/AddCabinPostDialog';
import BookClosed from '@/assets/bookClosed.svg';
import { mapGetters } from 'vuex';

export default {
  components: {
    CabinFeedItem,
    Button,
    BookClosed,
    AddCabinPostDialog
  },
  props: {
    cabinUserRoleId: Number,
    cabin: Object
  },
  data() {
    return {
      cabinFeedList: [],
    }
  },
  computed: {
    ...mapGetters(['users/userId', 'users/firstName', 'users/lastName']),
  },
  methods: {
    async getCabinFeed() {
      const { data } = await CabinFeedService.getCabinFeed(this.cabin.id);
      this.cabinFeedList = data;
    },
    async toggleAddCabinFeed() {
      const confirmAddCabinPostDialog = await this.$refs.addCabinPostDialog.show({
        title: 'Nytt innlegg',
        okButton: 'Publiser'
      });
      if(confirmAddCabinPostDialog) {
        let content = [{
          contentTypeId: 4,
          text: confirmAddCabinPostDialog.text,
          contentLanguageId: 1
        }];

        CabinFeedService.addCabinPost(this.cabin.id, this['users/firstName'], content).then(async res => {
          let saveData = Object.assign({}, res.data);
          if(confirmAddCabinPostDialog.image) {
            if(confirmAddCabinPostDialog.tempImage) {
              saveData.tempImage = confirmAddCabinPostDialog.tempImage;
            }
            let postContent = {
              cabinPostId: res.data.id,
              contentLanguageId: 1,
              contentTypeId: 5,
              text: confirmAddCabinPostDialog.image.name,
              contentAsBase64: confirmAddCabinPostDialog.image.base64,
              contentName: confirmAddCabinPostDialog.image.name,
              default: true,
              sequence: 0
            }
            CabinFeedService.addCabinPostContent(...Object.values(postContent)).then(res => {
              console.log(res);
            }).catch(err => {
              console.log(err);
            })
          }
          this.cabinFeedList.unshift(saveData);
          this.$toast.success(`Opprettet innlegg`);
        }).catch(err => {
          console.log(err);
          this.$toast.error(`Kunne ikke opprette innlegg`);
        })
      }
    },
    addComment() {
      this.getCabinFeed();
    },
    likeToggled() {
      this.getCabinFeed();
    },
    deletePost() {
      this.getCabinFeed();
    }
  },
  async beforeRouteEnter(to, from, next) {
    const { data } = await CabinFeedService.getCabinFeed(to.params.cabinId);
    next(vm => {
      if (data) {
        vm.cabinFeedList = data
      }
    });
  }
}
</script>
<style lang="scss" scoped>
  .cabin-book {
    margin-bottom: 5rem;
    @include gutter-padding;
    @media (min-width: $breakpoint-tablet) {
      width: 60%;
      margin: 1rem auto 3rem auto;
    }
    .mobile-button {
      display: none;
      @media (max-width: $breakpoint-mobile) {
        display: inline-block;
      }
      position: fixed;
      bottom: 100px;
      right: 20px;
      padding: 1rem 1.1rem;
      color: white;
      z-index: 999;
    }
    .desktop-button {
      display: none;
      @media (min-width: $breakpoint-mobile) {
        display: block;
      }
      width: 100%;
      margin-bottom: 2rem;
    }
    &_none {
      @media (min-width: $breakpoint-mobile) {
        width: 90%;
      }
      margin: 0 auto;
      &--inner {
        background-color: white;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem;
        svg {
          width: 100px;
          height: 100px;
          margin: 0 auto;
          margin-bottom: 2rem;
          fill: black;
        }
        p {
          text-align: center;
        }
      }
    }
  }
</style>
