<template>
  <div class="cabin-book-card">
    <div class="cabin-book-card_deletePost">
      <Close v-if="canDelete(cabinFeedItem.updatedBy)" @click="deletePost(cabinFeedItem)" />
    </div>
    <div v-if="cabinFeedItemImage" class="cabin-book-card_image">
      <img :src="cabinFeedItemImage">
    </div>
    <div class="cabin-book-card_content" :class="{ 'noimage': !cabinFeedItemImage }">
      <p>{{ cabinFeedItemText.text }}</p>
    </div>
    <div class="cabin-book-card_footer" :class="{ 'notExtended': !showComments }">
      <div class="cabin-book-card_footer-left">
        <span class="cabin-book-card_footer-left--like" :class="{ 'userHasLiked': hasLiked !== false }">
          <Heart @click="addLike()" />
          <span v-if="likeCount">{{ likeCount }}</span>
        </span>
        <span class="cabin-book-card_footer-left--comment" :class="{ 'active': showComments }">
          <Chat @click="toggleComments()" />
          <span v-if="commentCount">{{ commentCount }}</span>
        </span>
      </div>
      <div class="cabin-book-card_footer-right">
        <p>{{ cabinFeedItem.userName }} - {{ formatDate(cabinFeedItem.updatedTimeUtc) }}</p>
      </div>
    </div>
    <div v-if="showComments" class="cabin-book-card_comments">
      <div v-if="commentCount" class="cabin-book-card_comments--list">
        <CabinFeedItemComment
          v-for="item in cabinFeedItem.comments"
          :comment="item"
          :key="item.id"
        >
        </CabinFeedItemComment>
      </div>
      <div class="cabin-book-card_comments--add">
          <input v-model="commentToAdd" placeholder="Skriv en kommentar..." type="text">
          <Button :disabled="commentToAdd.length < 1" @click="addComment()" color="green">
            <Paperplane />
          </Button>
      </div>
    </div>
    <ConfirmationDialog ref="confirmationDialog"></ConfirmationDialog>
  </div>
</template>
<script>
import CabinFeedService from '@/services/CabinFeedService';
import { mapGetters } from 'vuex';
import CabinFeedItemComment from './CabinFeedItemComment';
import Button from '@/components/Button';
import Helpers from '@/helpers/helpers';
import Heart from '@/assets/heart.svg';
import Chat from '@/assets/chat.svg';
import Paperplane from '@/assets/paperplane.svg';
import Close from '@/assets/close.svg';
import ConfirmationDialog from '@/components/modals/ConfirmationDialog';

export default {
  name: 'CabinFeedItem',
  components: {
    Heart,
    Chat,
    Paperplane,
    Close,
    Button,
    CabinFeedItemComment,
    ConfirmationDialog
  },
  props: {
    cabinFeedItem: Object,
    storageToken: String,
    cabinUserRoleId: Number
  },
  data() {
    return {
      showComments: false,
      commentToAdd: ''
    }
  },
  computed: {
    ...mapGetters(['users/userId', 'users/firstName']),
    cabinFeedItemImage() {
      let contentImage = this.cabinFeedItem.content.find((item) => {
        return item.contentTypeId === 5;
      });

      if(contentImage) {
        return `${this.createUrlForCustomSize(contentImage.storageUrl, '')}${this.storageToken}`;
      } else if(this.cabinFeedItem.tempImage) {
        return this.cabinFeedItem.tempImage
      }

      return false;
    },
    cabinFeedItemText() {
      return this.cabinFeedItem.content.find((item) => {
        return item.contentTypeId === 4;
      })
    },
    likeCount() {
      return this.cabinFeedItem.likes.length || false;
    },
    commentCount() {
      return this.cabinFeedItem.comments.length || false;
    },
    hasLiked() {
      let hasLiked = this.cabinFeedItem.likes.findIndex((item) => {
        return this['users/userId'] === item.updatedBy;
      });

      if(hasLiked !== -1) return hasLiked;

      return false;
    }
  },
  methods: {
    formatDate: Helpers.formatDate,
    createUrlForCustomSize: Helpers.createUrlForCustomSize,
    addLike() {
      if(this.hasLiked === false) {
        CabinFeedService.addCabinPostLike(this.cabinFeedItem.id, this['users/firstName'], 1).then(res => {
          this.$emit('toggleLike', res.data);
        }).catch(err => {
          console.log('Error adding like', err);
        })
      } else {
        CabinFeedService.deleteCabinPostLike(this.cabinFeedItem.likes[this.hasLiked].id).then(res => {
          this.$emit('toggleLike', res.data);
        }).catch(err => {
          console.log('Error removing like', err);
        })
      }
    },
    toggleComments() {
      this.showComments = !this.showComments;
    },
    addComment() {
      CabinFeedService.addCabinPostComment(this.cabinFeedItem.id, this['users/firstName'], this.commentToAdd).then(res => {
        this.$emit('addedComment', res.data);
        this.commentToAdd = '';
      }).catch(err => {
        console.log(err);
      })
    },
    async deletePost(val) {
      if(!this.canDelete(val.updatedBy)) return;
      const confirmDelete = await this.$refs.confirmationDialog.show({
        title: 'Slett innlegg',
        message: 'Er du sikker på du vil slette dette innlegget?',
        okButton: 'Slett innlegg',
      })
      if (confirmDelete) {
        await CabinFeedService.deleteCabinPost(val.id);
        this.$emit('deleteCabinFeedPost');
        this.$toast.success(`Innlegg slettet`);
      } else {
        this.$toast.info(`Sletting avbrutt`);
      }
    },
    isCabinOwner() {
      if (this.cabinUserRoleId === 1) return true;

      return false;
    },
    isCabinAdministrator() {
      if (this.cabinUserRoleId === 2) return true;

      return false;
    },
    isCabinUser() {
      if (this.cabinUserRoleId === 3) return true;

      return false;
    },
    canDelete(val) {
      if(this.isCabinOwner() || this.isCabinAdministrator()) {
        return true;
      }

      if(this['users/userId'] === val) {
        return true;
      }

      return false;
    }
  }
}
</script>
<style lang="scss" scoped>
  .cabin-book-card {
    position: relative;
    border-radius: 14px;
    margin-bottom: 1.5rem;
    &_deletePost {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
      z-index: 9;
    }
    &_image {
      position: relative;
      background-color: white;
      display: flex;
      justify-content: center;
      margin-bottom: -5px;
      border-top-left-radius: 14px;
      border-top-right-radius: 14px;
      overflow: hidden;
      img {
        max-width: 100%;
      }
    }
    &_content {
      background-color: white;
      padding: 1.5rem;
      p {
        margin-bottom: 0;
      }
    }
    &_content.noimage {
      border-top-left-radius: 14px;
      border-top-right-radius: 14px;
    }
    &_footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      padding: 0 1.5rem 1.5rem 1.5rem;
      &-left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &--like, &--comment {
          display: flex;
          align-items: center;
          margin-right: 1rem;
          background-color: #EAEEE9;
          padding: .5rem;
          border-radius: 8px;
          span {
            margin-left: .5rem;
          }
          svg {
            cursor: pointer;
            stroke-width: 1.5;
            stroke: #1C2D31;
          }
        }
        &--like.userHasLiked {
          background-color: #F5CCCE;
          color: #EB303B;
          svg {
            fill: #EB303B;
            stroke: #EB303B;
          }
        }
        &--comment.active {
          background-color: #1F3333;
          color: white;
          svg {
            stroke: white;
          }
        }
      }
      &-right {
        p {
          margin-bottom: 0;
        }
      }
    }
    &_footer.notExtended {
      border-bottom-left-radius: 14px;
      border-bottom-right-radius: 14px;
    }
    &_comments {
      background-color: white;
      border-bottom-left-radius: 14px;
      border-bottom-right-radius: 14px;
      &--list, &--add {
        padding: 1.5rem;
        border-top: 1px solid #C4C4C4;
      }
      &--list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      &--add {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        input[type="text"] {
          background-color: #EAEEE9;
          margin-right: 1rem;
        }
      }
    }
  }
</style>
