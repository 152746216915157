<template>
    <DrawerModal ref="drawer">
      <div class="add-cabin-post">
        <div class="add-cabin-post_top">
          <h2>{{ title }}</h2>
          <Close @click="cancel" />
        </div>
        <div class="add-cabin-post_form">
          <form class="form">
              <textarea
                v-model="form.text"
                rows="10"
                cols="10"
                placeholder="Skriv tekst her..."
                class="input"
                name="text"
                ref="addCabinPostTextArea"
              />
          </form>
        </div>
        <div class="add-cabin-post_bottom">
          <div class="add-image">
            <label for="file-upload" class="upload-image">
              <Photo />
            </label>
            <input
              id="file-upload"
              type="file"
              @change="onFileChange($event)"
            />
          </div>
          <div v-if="selectedImage" class="selected-image">
            <img :src="selectedImage" />
          </div>
          <div class="btns">
              <span class="btn send-btn" @click="confirm">{{ okButton }}</span>
          </div>
        </div>
      </div>
    </DrawerModal>
</template>
<script>
import DrawerModal from './DrawerModal.vue'
import Photo from '@/assets/photo.svg';
import Close from '@/assets/close.svg';

export default {
    name: 'AddCabinPostDialog',
    components: {
      DrawerModal,
      Photo,
      Close
    },
    data: () => ({
        title: undefined,
        message: undefined,
        okButton: undefined,
        cancelButton: 'Avbryt',
        resolvePromise: undefined,
        rejectPromise: undefined,
        form: {
          text: '',
          image: false,
          tempImage: false
        },
        selectedImage: false
    }),

    methods: {
        show(opts = {}) {
            this.title = opts.title
            this.message = opts.message
            this.okButton = opts.okButton
            if (opts.cancelButton) {
                this.cancelButton = opts.cancelButton
            }
            this.$refs.drawer.open()
            this.$nextTick(function () {
                this.$refs.addCabinPostTextArea.focus()
            })
            return new Promise((resolve, reject) => {
              this.resolvePromise = resolve
              this.rejectPromise = reject
              this.clearForm();
            })
        },
        confirm() {
          this.$refs.drawer.close()
          this.resolvePromise(this.form)
        },
        cancel() {
          this.$refs.drawer.close()
          this.resolvePromise(false)
        },
        clearForm() {
          this.form.text =  '';
          this.form.image = false;
          this.selectedImage = false;
        },
        onFileChange(event) {
          this.createBase64Image(event.target.files[0]);
        },
        createBase64Image(fileObject) {
          const reader = new FileReader();
          reader.onload = (event) => {
            this.selectedImage = event.target.result;
            this.form.tempImage = event.target.result;
            let splitImage = this.selectedImage.split(',');
            let savingFileObject = {
              name: fileObject.name,
              base64: splitImage[1]
            }
            this.form.image = savingFileObject;
          }
          reader.readAsDataURL(fileObject);
        }
    },
}
</script>
<style lang="scss" scoped>
  .add-cabin-post {
    display: flex;
    flex-flow: column;
    height: 100%;
    &_top {
      flex: 0 1 auto;
      border-bottom: 1px solid $medium-grey;
      padding: 1.5rem 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        font-weight: 500;
        margin-bottom: 0;
      }
      svg {
        cursor: pointer;
      }
    }
    &_form {
      flex: 1 1 auto;
      background-color: #FFF;
      .form {
        height: 100%;
        margin-bottom: -10rem;
        input:focus {
          border: 1px solid $blue;
        }
        textarea {
          width: 100%;
          height: 100%;
          resize: none;
          padding: 1rem;
        }
      }
    }
    &_bottom {
      flex: 0 1 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #EDF0F4;
      .add-image, .btns {
        padding: 1rem 1rem .75rem 1rem;
      }
      .add-image {
        margin-left: 1rem;
        background-color: white;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        input[type="file"] {
          display: none;
        }
        .upload-image {
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }
      .selected-image {
        margin-right: auto;
        padding-left: 1rem;
        img {
          border-radius: 8px;
          width: 44px;
          height: 44px;
          margin-top: .2rem;
        }
      }
      .btns {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .btn {
          font-size: 13px;
          font-weight: 600;
          border-radius: 12px;
          padding: 0.75rem 1rem;
          color: white;
          text-decoration: none;
          cursor: pointer;
        }
        .send-btn {
          background-color: $hytta;
          &:hover {
            background-color: darken($hytta, 5%)
          }
        }
        .cancel-btn {
          background-color: $medium-grey;
          color: black;
          &:hover {
            background-color: darken($medium-grey, 5%)
          }
        }
      }
    }
  }
</style>
