<template>
    <popup-modal ref="popup">
        <h2 style="margin-top: 0">{{ title }}</h2>
        <p>{{ message }}</p>
        <div class="btns">
            <button class="btn cancel-btn" @click="cancel">{{ cancelButton }}</button>
            <span class="btn delete-btn" @click="confirm">{{ okButton }}</span>
        </div>
    </popup-modal>
</template>
<script>
import PopupModal from './PopupModal.vue'

export default {
    name: 'ConfirmDialogue',

    components: { PopupModal },

    data: () => ({
        title: undefined,
        message: undefined,
        okButton: undefined,
        cancelButton: 'Avbryt',
        resolvePromise: undefined,
        rejectPromise: undefined,
    }),

    methods: {
        show(opts = {}) {
            this.title = opts.title
            this.message = opts.message
            this.okButton = opts.okButton
            if (opts.cancelButton) {
                this.cancelButton = opts.cancelButton
            }
            this.$refs.popup.open()

            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        },
        confirm() {
            this.$refs.popup.close()
            this.resolvePromise(true)
        },
        cancel() {
            this.$refs.popup.close()
            this.resolvePromise(false)
        },
    },
}
</script>
<style lang="scss" scoped>
  p {
    margin-bottom: 2rem;
  }
  .btns {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
  }
  .btn {
    font-size: 13px;
    font-weight: 600;
    border-radius: 12px;
    padding: 0.75rem 1rem;
    color: white;
    text-decoration: none;
    cursor: pointer;
  }
  .delete-btn {
    background-color: $red;
    &:hover {
      background-color: darken($red, 5%)
    }
  }
  .cancel-btn {
    background-color: $medium-grey;
    color: black;
    &:hover {
      background-color: darken($medium-grey, 5%)
    }
  }
</style>
