<template>
  <div class="cabin-feed-item-comment">
    <div class="cabin-feed-item-comment_top">
      <small>{{ comment.userName }} - {{ formatDate(comment.updatedTimeUtc) }}</small>
    </div>
    <div class="cabin-feed-item-comment_content">
      {{ comment.comment }}
    </div>
  </div>

</template>
<script>
import Helpers from '@/helpers/helpers';

export default {
  name: 'CabinFeedItemComment',
  props: {
    comment: Object
  },
  methods: {
    formatDate: Helpers.formatDate,
  }
}
</script>
<style lang="scss" scoped>
  .cabin-feed-item-comment {
    background-color: #EAEEE9;
    padding: .75rem;
    border-radius: 8px;
    &_top {
      margin-bottom: .25rem;
    }
  }
  .cabin-feed-item-comment:not(:last-child) {
    margin-bottom: 1rem;
  }
</style>
